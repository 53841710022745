<template>
  <div class="row">
    <div class="col-8">
      <b-form-select v-model="filter.department" class="mr-1">
        <b-form-select-option :value="null"> Всі відділи </b-form-select-option>
        <b-form-select-option
          v-for="d in departments"
          :value="d.id"
          :key="d.id"
        >
          {{ d.name }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class="col-4">
      <b-button @click="addTopic" class="w-100" variant="success">
        + Додати
      </b-button>
    </div>
    <div class="col-12 pt-3">
      <b-overlay :show="isLoading">
        <div class="row">
          <div class="col-12 col-lg-6" v-for="t in filteredTopics" :key="t.uid">
            <b-card class="topic-item mb-2 p-0">
              <h5 class="mb-1">
                {{ t.name }}
              </h5>
              <div class="d-flex align-items-center">
                <span
                  :style="{
                    background: colorByString(
                      getDepartmentName(t.department_id)
                    ),
                  }"
                  class="badge badge-primary mr-auto"
                >
                  {{ getDepartmentName(t.department_id) }}
                </span>
                <b-button
                  @click="remove(t)"
                  variant="outline-danger mr-1"
                  size="sm"
                >
                  Видалити
                </b-button>
                <b-button @click="edit(t)" variant="outline-primary" size="sm">
                  Редагувати
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
      </b-overlay>
    </div>
    <add-topic-modal
      @update="getTopics"
      :departments="departments"
      ref="addTopicModal"
    />
  </div>
</template>

<script>
import addTopicModal from "@/components/modals/add-edit-topic.vue";
export default {
  components: {
    addTopicModal,
  },
  data() {
    return {
      isLoading: false,
      departments: [],
      topics: [],
      filter: {
        department: null,
        topic: null,
      },
    };
  },
  computed: {
    filteredTopics() {
      let topics = [...this.topics];
      if (this.filter.department) {
        topics = topics.filter(
          (t) => t.department_id === this.filter.department
        );
      }

      //sort by department_id
      topics.sort((a, b) => {
        if (a.department_id > b.department_id) {
          return 1;
        }
        if (a.department_id < b.department_id) {
          return -1;
        }
        return 0;
      });

      return topics;
    },
  },
  methods: {
    async getDepartments() {
      this.isLoading = true;
      let args = {
        collectionName: "departments",
      };
      var result = await this.$db.getCollection(args);
      this.departments = result.docs.filter((d) => d.is_position !== true);
      this.isLoading = false;
    },
    async getTopics() {
      this.isLoading = true;
      let args = {
        collectionName: "topics",
      };
      var result = await this.$db.getCollection(args);
      this.topics = result.docs;
      this.isLoading = false;
    },
    addTopic() {
      this.$refs.addTopicModal.show = true;
    },
    edit(topic) {
      this.$refs.addTopicModal.show = true;
      this.$refs.addTopicModal.usecase = "edit";
      this.$refs.addTopicModal.topic = { ...topic };
    },
    remove(topic) {
      //use native confirm dialog
      if (confirm("Видалити тему?")) {
        this.$db.delete("topics", topic.uid);
        this.getTopics();
      }
    },
    colorByString(string) {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = "#";
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }

      // make sure color is contrast enough to have white text
      let r = parseInt(colour.substr(1, 2), 16);
      let g = parseInt(colour.substr(3, 2), 16);
      let b = parseInt(colour.substr(5, 2), 16);
      let yiq = (r * 299 + g * 587 + b * 114) / 1000;
      if (yiq < 128) {
        colour = "#ffffff";
      }

      return colour;
    },
    getDepartmentName(id) {
      let department = this.departments.find((d) => d.id === id);
      return department.name || "";
    },
  },
  mounted() {
    this.getDepartments();
    this.getTopics();
  },
};
</script>

<style lang="scss" scoped></style>
